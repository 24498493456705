<template>
  <div
    class="modal"
    style="z-index: 1005 !important;"
     v-bind:class="{ 'is-active': showEditModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Zone</p>
        <button class="delete" aria-label="close" @click="toggleClose"></button>
      </header>
      <section class="modal-card-body">
        <!-- <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                <div class="field">
                  <label class="label">Name </label>
                  <input type="text" v-model="editSelectedZone.value" />
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
          <template v-if="environment === 'logistics'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-third" v-if="environment === 'logistics'">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Base Charge:
                </div>
                <input class="input" type="number" v-model="zoneProps.BaseCharge" >
              </div>
              <div class="column is-one-third" v-if="environment === 'logistics'">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Delivery Surcharge:
                </div>
                <input class="input" type="number" v-model="zoneProps.DeliverySurcharge" >
              </div>
              <div class="column is-one-third" v-if="environment === 'logistics'">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Unit Charge:
                </div>
                <input class="input" type="number" v-model="zoneProps.UnitCharge" >
              </div>
            </div>
            <div class="columns" style="width: 100%">
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Product Surcharge:
                </div>
                <input class="input" type="number" v-model="zoneProps.ProductSurcharge" >
              </div>

              <div class="column is-one-third" v-if="environment === 'logistics'">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Maximum Charge:
                </div>
                <input class="input" type="number" v-model="zoneProps.MaximumCharge" >
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Recycling:
                </div>
                <input class="input" type="text" v-model="zoneProps.Recycling" >
              </div>
            </div>
            <div class="columns" style="width: 100%">
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Description English:
                </div>
                <input class="input" type="text" v-model="zoneProps.DescriptionEnglish" >
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Description French:
                </div>
                <input class="input" type="text" v-model="zoneProps.DescriptionFrench" >
              </div>
            </div>
          </template>
          <template v-if="environment === 'installation'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Simple Installation:
                </div>
                <input class="input" type="text" v-model="zoneProps.SimpleInstallation" >
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Complex Installation:
                </div>
                <input class="input" type="text" v-model="zoneProps.ComplexInstallation" >
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Inst. Trip Charge:
                </div>
                <input class="input" type="number" v-model="zoneProps.InstTripCharge" >
              </div>
            </div>
          </template>
          <template>
            <div class="columns" style="width: 100%">
              <div class="column is-one-third">
                <div
                  class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Color:
                </div>
                <input class="input" placeholder="#FFFFFF" type="text" v-model="zoneProps.color" >
              </div>
            </div>
          </template>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          @click="editZone"
          v-bind:class="
            isEditing ? editButtonLoading : editButtonNotLoading
          "
        >
          Save
        </button>
        <button
          class="button is-small level-item is-rounded"
          @click="toggleClose"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { zonesInfoCollection, editZoneInfo } from '@/store/fireStore'
import store from '../../store'

export default {
  data () {
    return {
      environment: store.state.environment.toLowerCase(),
      isEditing: false,
      editButtonLoading:
        'button is-small is-info level-item is-rounded is-loading',
      editButtonNotLoading:
        'button is-small is-info level-item is-rounded',
      editSelectedZone: {
        value: '',
      },
      zoneProps: {
        BaseCharge: 0,
        DeliverySurcharge: 0,
        UnitCharge: 0,
        ProductSurcharge: 0,
        InstTripCharge: 0,
        MaximumCharge: 0,
        SimpleInstallation: '',
        ComplexInstallation: '',
        Recycling: '',
        DescriptionEnglish: '',
        DescriptionFrench: '',
        color: ''
      },
    }
  },
  props: {
    showEditModal: {
      type: Boolean
    },
    selectedZone: {
      type: Object
    }
  },
  methods: {
    toggleClose () {
      this.$emit('closeModal')
    },
    toggleRender () {
      this.$emit('renderMap')
    },
    async editZone () {
      this.isEditing = true
      this.editSelectedZone.key = this.editSelectedZone.value
      const { key, value, selected, id } = this.editSelectedZone
      console.log({
        BaseCharge: Number(this.zoneProps.BaseCharge),
        DeliverySurcharge: Number(this.zoneProps.DeliverySurcharge),
        UnitCharge: Number(this.zoneProps.UnitCharge),
        ProductSurcharge: Number(this.zoneProps.ProductSurcharge),
        InstTripCharge: Number(this.zoneProps.InstTripCharge),
        MaximumCharge: Number(this.zoneProps.MaximumCharge),
        SimpleInstallation: this.zoneProps.SimpleInstallation,
        ComplexInstallation: this.zoneProps.ComplexInstallation,
        Recycling: this.zoneProps.Recycling,
        DescriptionEnglish: this.zoneProps.DescriptionEnglish,
        DescriptionFrench: this.zoneProps.DescriptionFrench,
        color: this.zoneProps.color,
      })

      let editedZone = {
      }

      if (this.environment === "logistics") {
        editedZone = {
            // ...this.zoneProps,
            BaseCharge: Number(this.zoneProps.BaseCharge),
            DeliverySurcharge: Number(this.zoneProps.DeliverySurcharge),
            UnitCharge: Number(this.zoneProps.UnitCharge),
            ProductSurcharge: Number(this.zoneProps.ProductSurcharge),
            MaximumCharge: Number(this.zoneProps.MaximumCharge),
            Recycling: this.zoneProps.Recycling,
            DescriptionEnglish: this.zoneProps.DescriptionEnglish,
            DescriptionFrench: this.zoneProps.DescriptionFrench,

          }
      } else if (this.environment === "installation") {
        editedZone = {
            // ...this.zoneProps,
            InstTripCharge: Number(this.zoneProps.InstTripCharge),
            SimpleInstallation: this.zoneProps.SimpleInstallation,
            ComplexInstallation: this.zoneProps.ComplexInstallation,
            color: this.zoneProps.color,
          }
      } else {
        editedZone = {
          color: this.zoneProps.color,
        }
      }

      try {
        await zonesInfoCollection
          .doc(this.editSelectedZone.id)
          .update({ ...editedZone })
          .then(() => {
            this.isEditing = false
            this.$emit('renderMap')
            this.toggleClose()
            this.$notify({
              type: 'success',
              group: 'zones',
              title: 'Zone Save Successfully'
            })
          })
      } catch (e) {
        this.isEditing = false
        this.$notify({
          type: 'error',
          group: 'zones',
          title: 'There was an error, please try again'
        })
        console.log(e)
        throw e
      }
    }
  },
  mounted () {
    console.log('this.selectedZone:', this.selectedZone)
    this.editSelectedZone = Object.assign({}, this.selectedZone)
    const {
      color, BaseCharge, DeliverySurcharge, UnitCharge, ProductSurcharge,
      InstTripCharge, MaximumCharge, SimpleInstallation, ComplexInstallation,
      Recycling, DescriptionEnglish, DescriptionFrench
    } = this.selectedZone
    this.zoneProps = {
      color: color || '',
      BaseCharge: BaseCharge || 0,
      DeliverySurcharge: DeliverySurcharge || 0,
      UnitCharge: UnitCharge || 0,
      //
      ProductSurcharge: ProductSurcharge || 0,
      InstTripCharge: InstTripCharge || 0,
      MaximumCharge: MaximumCharge || 0,
      SimpleInstallation: SimpleInstallation || '',
      ComplexInstallation: ComplexInstallation || '',
      Recycling: Recycling || '',
      DescriptionEnglish: DescriptionEnglish || '',
      DescriptionFrench: DescriptionFrench || ''
    }
  }
}
</script>
<style>
</style>
